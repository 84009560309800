//
// pricing.scss
//

.pricing-box {
  border: 1px solid $gray-200;
  position: relative;
  .pricing-plan {
    padding: 20px 0px;
  }
  .pricing-price {
    padding: 30px 0px;
    border-bottom: 1px solid $gray-200;
    border-top: 1px solid $gray-200;
  }
  .pricing-features {
    padding: 40px 0px;
  }

  .pricing-lable {
    position: absolute;
    display: block;
    top: 0;
    right: 0px;
  }
  .lable-name {
    position: relative;
    background-color: $primary;
    color: $white;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 90px;
    -webkit-transform: rotate(-90deg) translateX(-27%);
    transform: rotate(-90deg) translateX(-27%);
    &:after {
      content: "";
      border-top: 20px solid $primary;
      border-bottom: 20px solid $primary;
      border-right: 11px solid transparent;
      border-left: 11px solid transparent;
      position: absolute;
      left: -11px;
      top: 0;
    }
  }
}
