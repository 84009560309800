//
// contact.scss
//

.custom-form {
  .alert {
    padding: 11px;
    font-size: 15px;
  }
}

textarea.form-control {
  height: auto !important;
}
.form-control {
  margin-bottom: 15px;
  box-shadow: none !important;
  height: 48px;
  padding-left: 20px;
  border: 1px solid lighten($gray-500,15%);
  font-size: 14px;
  border-radius: 5px;
  &:focus {
    border-color: $gray-500;
  }
}

textarea.form-control {
  height: auto;
  padding-top: 13.5px;
}

.contact-social {
  a {
    font-size: 20px;
    color: $dark;
    transition: all 0.5s ease-in-out;
    &:hover {
      color: $primary;
    }
    i {
      margin-right: 10px;
    }
  }
}
