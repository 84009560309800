//
// general.scss
//

body {
  font-family: $font-family-base;
  line-height: 26px;
  color: $dark;
  font-weight: 400;

}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  line-height: 1.5;
}

.btn,
.btn:focus,
button,
button:focus {
  outline: none !important;
}

a {
  text-decoration: none !important;
  outline: none;
}

p {
  font-size: 15px;
}

label {
  font-weight: 500;
}