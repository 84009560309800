.card-style {
    position: relative;
    /* width: 50%; */
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #046382ce;
    overflow: hidden;
    width: 0;
    height: 100%;
    transition: .5s ease;
}

.card-style:hover .overlay {
    width: 100%;
}

.text {
    color: white;
    position: relative;
    overflow: hidden;
    /* text-align: justify; */
    top: 50%;
    left: 50%;
    padding-right: 10px;
    padding-left: 10px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.title {
    font-size: 30px;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: bold;
}

@media (max-width:768px) {
    .title {
        font-size: 20px;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;
        font-weight: bold;
    }
}

.card-shadow {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.custom-bullet-green {
    background-color: #d9f380;
    outline: 3px solid #7ba754;
    border-radius: 1px;
    border: 4px solid white;
    height: 18px;
    width: 18px;
    transform: rotate(45deg);
}

.custom-bullet-light-blue {
    background-color: #4fc8dc;
    outline: 3px solid #317a8b;
    border-radius: 1px;
    border: 4px solid white;
    height: 18px;
    width: 18px;
    transform: rotate(45deg);
}

.custom-bullet-blue {
    background-color: #2e266d;
    outline: 3px solid #76b9f0;
    border-radius: 1px;
    border: 4px solid white;
    height: 18px;
    width: 18px;
    transform: rotate(45deg);
}

.custom-bullet-orange {
    background-color: #f79630;
    outline: 3px solid black;
    border-radius: 1px;
    border: 4px solid white;
    height: 18px;
    width: 18px;
    transform: rotate(45deg);
}

.custom-bullet-violet {
    background-color: #776ad4;
    outline: 3px solid #e05f69;
    border-radius: 1px;
    border: 4px solid white;
    height: 18px;
    width: 18px;
    transform: rotate(45deg);
}

.text-light-blue {
    color: #317a8b;
}

.text-blue {
    color: #2e266d;
}

.text-green {
    color: #317a8b;
}

.text-orange {
    color: #f79630;
}

.text-violet {
    color: #776ad4;
}

.text-justify {
    text-align: justify;
}