//
// helper.scss
//

.section-sm {
  padding-bottom: 80px;
  padding-top: 80px;
  position: relative;
}

.section {
  padding-bottom: 100px;
  padding-top: 100px;
  position: relative;
}

.section-lg {
  padding-bottom: 110px;
  padding-top: 110px;
  position: relative;
}

.bg-lightan {
  background: linear-gradient(to top, rgba($primary, 0.1) 30%, $white 100%);
}

.home-center {
  display: table;
  width: 100%;
  height: 100%;
}

.home-desc-center {
  display: table-cell;
  vertical-align: middle;
}

// hoverlay

.bg-overlay {
  background-color: rgba(36, 42, 53, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
}

.color-overlay {
  background-color: $blue;
  opacity: 0.9;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
}

.overlay-gradient {
  background: $bg-gradient;
  opacity: 0.9;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
}

.flex-1 {
  flex: 1;
}

// TITLE

.heading-box {
  .heading-desc {
    max-width: 700px;
    margin: 0 auto;
  }
}

.btn-round {
  border-radius: 30px;
}

.row > * {
  position: relative;
}

hr {
  border: 1px solid #e2e2e2;
}

// font-size

.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: 12px;
}

.f-13 {
  font-size: 13px;
}

.f-14 {
  font-size: 14px;
}

.f-15 {
  font-size: 15px;
}

.f-16 {
  font-size: 16px;
}

.f-17 {
  font-size: 17px;
}

.f-18 {
  font-size: 18px;
}

.f-19 {
  font-size: 19px;
}

.f-20 {
  font-size: 20px;
}

// linehight

.line-height_1_4 {
  line-height: 1.4;
}

.line-height_1_6 {
  line-height: 1.6;
}

.line-height_1_8 {
  line-height: 1.8;
}

// FONT WEIGHT

.fw-normal {
  font-weight: 300 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

// HOVER EFFACT

.hover-effect {
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: translateY(-1rem);
  }
}
