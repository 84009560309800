//
// Counter.scss
//


.bg-counter{
  background-image: url(../images/img-2.jpg);
  background-position: center;
}



// CTA

.bg-cta{

  background-image: url(../images/img-3.jpg);
  background-position: center;

}