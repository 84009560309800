//Import Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

//Import Icons
@import "./style.scss";

.close {
  background-color: #fff !important;
  border: 0;
  font-size: 12px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;

  span {
    display: none;
  }
}